import React from "react"
import { Container, Heading, Box, Embed, Image } from "theme-ui"
import Logo from "../../images/logo-ab.jpg"
import { Helmet } from "react-helmet"

const PaginaInvito = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Box sx={{ minHeight: "100vh" }}>
      <Container variant="md" sx={{ py: [5] }}>
        <Image src={Logo} alt="Logo AB" sx={{ height: "80px", display: "block", margin: "0 auto" }} />
        <Heading as="h1" sx={{ textAlign: "center", mb: [5], mt: [5] }}>
          L'invito di Angelo Baronchelli, Presidente di AB
        </Heading>

        <Embed src="https://www.youtube-nocookie.com/embed/uUUswvPVTfI?rel=0" />
      </Container>
      </Box>
    </>
  )
}

export default PaginaInvito
